<template>
    <div>
        <div class="card">
            <div class="mb-3" style="justify-content:space-between;display: flex;border-radius: 4px">
                <div>
                    <label>选择日期：</label>
                    <Calendar inputId="range" v-model="orderDate" dateFormat="yy-mm-dd" selectionMode="range" :manualInput="false" :hideOnRangeSelection="true" :showIcon="true" class="mr-3" />
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filters['global'].value" placeholder="搜索订单商品SKU、ASIN" />
                    </span>
                    <Button type="button" icon="pi pi-refresh" label="刷新" class="ml-3" @click="getOrderList()"/>
                </div>
                <div>
                    <Button type="button" icon="pi pi-filter-slash" label="清除" class="p-button-outlined" @click="clearFilter()"/>
                </div>
            </div>
            <DataTable stripedRows scrollable scrollHeight="calc(100vh - 16.5rem)" :value="orders" :paginator="true" :rowsPerPageOptions="[10,20,50]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="订单总数：{totalRecords}" class="p-datatable-customers" :rows="20"
                dataKey="AmazonOrderId" v-model:filters="filters" :globalFilterFields="['ASIN','skus']" filterDisplay="menu" :loading="loading" responsiveLayout="scroll">
                
                <template #empty>
                    {{isempty}}
                </template>
                
                <Column field="AmazonOrderId" header="订单编号" style="min-width:15rem"></Column>
                <Column header="订单时间" field="PurchaseDate" dataType="time" style="min-width:12rem"></Column>

                <Column header="商品信息" field="Items" style="min-width:22rem">
                <template #body="{data}">
                    <div style="display: block;">
                        <itemInfo :skuClass="'mb-2'" v-for="item in data.Items" :key="item.ASIN"  :item="{imgUrl:item.SmallImage,asin:'1',fnsku:'1'}">
                            <template #asin>
                                <a target="_blank" :href="'https://www.amazon.com/dp/'+item.ASIN">{{item.ASIN}}</a>
                            </template>
                            <template #fnsku>
                                {{item.SellerSKU}} X {{item.QuantityOrdered}}
                            </template>
                        </itemInfo>
                    </div>
                </template>
                </Column>

                <Column header="订单金额" dataType="numeric" style="min-width:10rem">
                    <template #body="{data}">
                        {{formatCurrency(data.Amount)}}
                    </template>
                </Column>

                <Column header="店铺" field="Store.name" :filterMenuStyle="{'height':'5.3rem'}" :showClearButton="false" :showApplyButton="false" :showFilterMatchModes="false" style="min-width:10rem">
                    <template #filter="{filterModel,filterCallback}">
                        <Dropdown v-model="filterModel.value" @change="filterCallback()" :showClear="true" :options="stores" placeholder="全部店铺" optionLabel="storeName" optionValue="storeName" class="p-column-filter" />
                    </template>
                </Column>

                <Column field="FulfillmentChannel" header="配送渠道" :showFilterMatchModes="false" :showFilterOperator="false" style="min-width:12rem">
                    <template #body="{data}">
                        <span>{{translate(data.FulfillmentChannel)}}</span>
                    </template>
                    <template #filter="{filterModel}">
                            <Dropdown v-model="filterModel.value" :options="FulfillmentChannels" placeholder="全部" class="p-column-filter" :showClear="true">
                                <template #value="slotProps">
                                    <span v-if="slotProps.value">{{translate(slotProps.value)}}</span>
                                    <span v-else>{{slotProps.placeholder}}</span>
                                </template>
                                <template #option="slotProps">
                                    <span>{{translate(slotProps.option)}}</span>
                                </template>
                            </Dropdown>
                        </template>
                </Column>
                <Column field="ShipServiceLevel" header="配送服务" :showFilterMatchModes="false"  style="min-width:12rem">
                    <template #body="{data}">
                        {{translate(data.ShipServiceLevel)}}
                    </template>
                    <template #filter="{filterModel}">
                            <Dropdown v-model="filterModel.value" :options="ShipServiceLevels" placeholder="全部" class="p-column-filter" :showClear="true">
                                <template #value="slotProps">
                                    <span v-if="slotProps.value">{{translate(slotProps.value)}}</span>
                                    <span v-else>{{slotProps.placeholder}}</span>
                                </template>
                                <template #option="slotProps">
                                    <span>{{translate(slotProps.option)}}</span>
                                </template>
                            </Dropdown>
                        </template>
                </Column>
                <Column field="OrderStatus" header="订单状态" :showFilterMatchModes="false" bodyClass="text-center" style="min-width:8rem">
                    <template #body="{data}">
                        {{translate(data.OrderStatus)}}
                    </template>
                    <template #filter="{filterModel}">
                            <Dropdown v-model="filterModel.value" :options="OrderStatues" placeholder="全部" class="p-column-filter" :showClear="true">
                                <template #value="slotProps">
                                    <span v-if="slotProps.value">{{translate(slotProps.value)}}</span>
                                    <span v-else>{{slotProps.placeholder}}</span>
                                </template>
                                <template #option="slotProps">
                                    <span>{{translate(slotProps.option)}}</span>
                                </template>
                            </Dropdown>
                        </template>
                </Column>
                
            </DataTable>
        </div>
    </div>

</template>

<script>
import Service from '../../service/Service';
import {FilterMatchMode} from 'primevue/api';
import itemInfo from '../common/itemInfo.vue';

export default {
    data() {
        return {
            daylight:{
                dateStart:null,
                dateEnd:null
            },
            user:null,
            orderDate:null,
            orders:[],
            filters: null,
            stores: [],
            ShipServiceLevels:["Expedited","SecondDay","NextDay","Standard"],
            FulfillmentChannels:["AFN","MFN"],
            OrderStatues:["Pending","Shipped","Canceled"],
            translates: {
                Pending:'等待中',
                Shipped: '已发货', 
                Canceled:'已取消',
                AFN:'亚马逊配送',
                MFN:'卖家配送',
                Expedited:'加急',
                SecondDay:'隔日达',
                NextDay:'次日达',
                Standard:'标准配送'
            },
            loading: true,
            isempty:''
        }
    },
    created() {
        this.service = new Service();
        this.initFilters();
    },
    mounted() {
        this.daylight.dateStart=localStorage.getItem('dateStart');
        this.daylight.dateEnd=localStorage.getItem('dateEnd');
        this.user=JSON.parse(localStorage.getItem('user'));
        this.stores=JSON.parse(localStorage.getItem('stores'));
        if(!localStorage.getItem("OrderStartDate")){
            var date=new Date();
            if(this.daylight.dateStart<=date.getTime()&&date.getTime()<this.daylight.dateEnd){
                date.setTime(date.valueOf()-15*60*60*1000);
			}
			else{
				date.setTime(date.valueOf()-16*60*60*1000);
			}
            date=this.formatDate(date);
            localStorage.setItem("OrderStartDate",date);
            localStorage.setItem("OrderEndDate",date);
            this.orderDate=[new Date(date),new Date(date)];
        }
        else{
            this.orderDate=[new Date(localStorage.getItem('OrderStartDate')),new Date(localStorage.getItem('OrderEndDate'))];

        }
    },
    watch:{
        orderDate:function(val,oldval){
            if(val[1]!=null){
                var date=[];
                date[0]=this.formatDate(val[0]);
                date[1]=this.formatDate(val[1]);
                if(oldval!=null){
                    localStorage.setItem("OrderStartDate",date[0]);
                    localStorage.setItem("OrderEndDate",date[1]);
                    this.getOrderList();
                }
                else{
                    this.getOrderList();
                }
            }
        },
    },
    methods: {
        formatDate(date) {
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            return y + '-' + m + '-' + d;
        },
        getOrderList(){
            this.isempty='正在加载...';
            this.orders = null; 
            this.loading = true;
            var date=[localStorage.getItem("OrderStartDate"),localStorage.getItem("OrderEndDate")];
            const formData = new FormData();
            formData.append('OrderStartDate', date[0]);
            formData.append('OrderEndDate', date[1]);
            formData.append('userid', this.user.UserId);
            formData.append('owner', this.user.Owner);
            var url='order/getorderlist.php';
            this.service.sendHttp(url,'POST',formData).then(res=>res.json()).then(data => {
                this.loading = false;
                this.isempty='无数据！';
                if(data.result=='success'){
                    data.orders.forEach(order => {
                        order.Items.forEach(item=>{
                            if(order.skus)
                            order.skus+=item.SellerSKU;
                            else
                            order.skus=item.SellerSKU;
                        });
                    });
                    this.orders=data.orders;
                }
            });
        },
        translate(value) {
            return this.translates[value];
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
        },
        clearFilter() {
            this.initFilters();
        },
        initFilters() {
            this.filters = {
                'FulfillmentChannel': {value: null, matchMode: FilterMatchMode.EQUALS},
                'Store.name': {value: null, matchMode: FilterMatchMode.EQUALS},
                'ShipServiceLevel': {value: null, matchMode: FilterMatchMode.EQUALS},
                'OrderStatus': {value: null, matchMode: FilterMatchMode.EQUALS},
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
            }
        },
    },
    components: {
        'itemInfo': itemInfo
    }
}
</script>